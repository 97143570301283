import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="TERMS AND CONDITIONS" />
    <h2>TERMS AND CONDITIONS</h2>
    <p>PLEASE READ THESE TERMS & CONDITIONS CAREFULLY BEFORE USING THE PLATFORM. BY YOUR USE OF THE PLATFORM, YOU AGREE TO THESE TERMS &
      CONDITIONS.</p>
    <p>The following terms & conditions and any other rules posted on our Platform (collectively the "T&C") constitute an agreement between
      Leem Trading Establishment ("Leem", "we", "our", "us") and you, the visitor, governing your access and use of all content and
      functionalities available on the Leem Platform, related micro-Platforms accessed through the URL www.leem.com, related domain names,
      mobile application, any other Platform or micro-Platform of Leem (collectively the "Platform").
    </p>
    <p>Leem platform is owned and operated by Leem Trading Establishment, which is a subsidiary of Al Hala Trading Est. under the laws of
      the Kingdom of Saudi Arabia, with commercial license number 1010612693, registered office at 8592 Al Ulaya , Riyadh, 12333, Kingdom of
      Saudi Arabia</p>
    <p>By visiting the Platform, you are accepting and consenting to the practices described in the T&C. We may modify the T&C from time to
      time and your continued use of this Platform (or any of our other Platforms) following such change shall signify your agreement to be
      bound by the modified T&C.</p>
    <p>Those persons wishing to use our services must read and agree to be bound by our policies relating to those services. In particular,
      we draw your attention to our policies relating to the terms of purchase and our Privacy Policy. If you are under 18, you must let
      your parent or guardian know about Leem's Privacy Policy before you register to use this Platform or any of this Platform's
      services.</p>
    <p>Please read the T&C and check back often. If you do not agree to any change to the T&C, then you must immediately stop using the
      Platform.</p>
    <ul>
      <li><a href="#registration">Registration & Account Terms and Conditions</a></li>
      <li><a href="#eligibility">Eligibility to purchase</a></li>
      <li><a href="#pricing">Pricing policy</a></li>
      <li><a href="#orders">Orders</a></li>
      <li><a href="#acceptance">Acceptance of your order</a></li>
      <li><a href="#payment">Payment</a></li>
      <li><a href="#promotion">Promotion codes</a></li>
      <li><a href="#insurance">Insurance and delivery</a></li>
      <li><a href="#returns">Returns and exchanges</a></li>
      <li><a href="#intellectual">Intellectual property rights</a></li>
      <li><a href="#content">Content</a></li>
      <li><a href="#use">No commercial use</a></li>
      <li><a href="#activity">Your activity</a></li>
      <li><a href="#third">Third party</a></li>
      <li><a href="#general">General</a></li>
      <li><a href="#indemnification">Indemnification</a></li>
      <li><a href="#legal">General legal terms</a></li>
    </ul>
    <a name="registration" />
    <h2>REGISTRATION & ACCOUNT TERMS AND CONDITIONS</h2>
    <p>To use some of the services or features made available to you on this Platform you will need to register for an account. When you
      register you are required to provide information about yourself that is true, accurate, current, and complete in all respects. Should
      any of your registration information change, please notify us immediately at the following e-mail address customercare@leem.com. We
      may also change registration requirements from time to time.</p>
    <p>Here are the some of the benefits you’ll be able to enjoy by registering with us for a Leem account:</p>
    <ul>
      <li>Track your orders and review past purchases</li>
      <li>Add sold out items to your Wish List so you can see if more stock arrives</li>
      <li>Save your address and card details so you can shop even quicker next time</li>
      <li>Manage your account details, address book and email preferences</li>
    </ul>
    <p>To create an account, simply click on SIGN IN and then REGISTER NOW to fill in your details. The account password you create should
      be unique and kept secure, and you must notify Leem immediately of any breach of security or unauthorized use of your account.</p>
    <p>Forgotten password</p>
    <p>If you have forgotten your password, follow the FORGOTTEN PASSWORD instructions on the SIGN IN page. For security reasons we are
      unable to send your password via email.</p>
    <p>Please refer to our Privacy Policy for information about how we use your data.</p>
    <a name="eligibility" />
    <h2>ELIGIBILITY TO PURCHASE</h2>
    <p>The purchase of merchandise through Leem is strictly limited to parties who can lawfully enter into and form contracts on the
      Internet in accordance with the laws of the Kingdom of Saudi Arabia</p>
    <p>In order to make purchases on the Platform you will be required to provide your personal details. In particular, you must provide
      your real name, phone number, e-mail address and other requested information as indicated. Furthermore, you will be required to
      provide payment details that you represent and warrant are both valid and correct and you confirm that you are the person referred to
      in the Billing information provided.</p>
    <p>The Platform is available only to individuals and others who meet the Leem terms of eligibility, who have been issued a valid
      credit/debit card by a bank acceptable to Leem, and who have authorized Leem to process a charge or charges on their credit/debit card
      in the amount of the total purchase price for the merchandise which they purchase. Products purchased by the buyer are for personal or
      gift use and should not be re-sold, used for commercial purposes or any other commercial benefit. In addition, Leem reserves the right
      to restrict multiple quantities of an item being shipped to any one customer or postal address.</p>
    <p>By making an offer to purchase merchandise, you expressly authorized us to perform credit checks and, where Leem feels necessary, to
      transmit or to obtain your credit card information or credit report information (including any updated information) to or from third
      parties solely to authenticate your identity, to validate your credit/debit card, to obtain an initial credit card authorization
      and/or to authorize individual purchase transactions.</p>
    <p>Furthermore, you agree that we may use Personal Information provided by you in order to conduct appropriate anti-fraud checks.
      Personal Information that you provide may be disclosed to a credit reference or fraud prevention agency, which may keep a record of
      that information.</p>
    <p>Please refer to our Privacy Policy for further information about how we use your data.</p>
    <a name="pricing" />
    <h2>PRICING POLICY</h2>
    <p>Prices shown on the Platform are in Saudi Riyal / UAE Dirham / Bahraini Dinar / US Dollars and are inclusive of taxes. If you are
      shipping within Saudi Arabia, the UAE or Bahrain, local sales tax will be charged on all orders (where applicable).</p>
    <p>From time to time, prices are subject to change in response to currency exchange rate changes, markdowns and other commercial
      factors. The price applicable to your order will be the price current at the time your order is accepted.</p>
    <p>Customers purchasing from a country served as DDP will incur relevant import duty and tax. These costs are included in the final
      purchase price. Customers purchasing from a country served as DDU will be charged for the items purchased and shipping costs only.
      Import duty or tax costs will be invoiced to you directly from DHL or an import broker appointed by you. We recommend you contact your
      local customs authority to determine a landed cost price prior to purchase completion.</p>
    <p>If you are a customer whose credit/debit card is not denominated in Saudi Riyal, UAE Dirham or US Dollars, the final price will be
      calculated in accordance with the applicable exchange rate on the day your card issuer processes the transaction.</p>
    <a name="orders" />
    <h2>ORDERS</h2>
    <p>All orders are subject to acceptance and availability, and Items in your shopping basket are not reserved and may be purchased by
      other customers.</p>
    <p>Leem offers products for sale that are in stock and available for dispatch from our distribution center.</p>
    <p>Leem will store a record of your transactions for a minimum of one year.</p>
    <a name="acceptance" />
    <h2>ACCEPTANCE OF YOUR ORDER</h2>
    <p>Once you have made your choice and your order has been placed, you will receive an email acknowledging the details of your order.
      This email is NOT an acceptance of your order, just a confirmation that we have received it.</p>
    <p>Unless you cancel your order, acceptance of your order will be perfected upon completion of the packing of your order. Completion of
      the contract between you and Leem will be perfected when we deliver the goods to you. Neither our third-party payment processor nor
      our nominated courier has the authority to accept an order on behalf of Leem.</p>
    <p>We reserve the right not to accept your order in the event, for example, that we are unable to obtain authorization for payment, that
      shipping restrictions apply to a particular item, that the item ordered is out of stock or does not satisfy our quality control
      standards and is withdrawn, or that you do not meet the eligibility criteria set-out within the T&C.</p>
    <p>Furthermore, we may refuse to process a transaction for any reason or refuse service to anyone at any time at our sole discretion. We
      will not be liable to you or any third party by reason of our withdrawing any merchandise from the Platform whether or not that
      merchandise has been sold, removing, screening or editing any materials or content on the Platform, refusing to process a transaction
      or unwinding or suspending any transaction after processing has begun.</p>
    <p>Whilst every effort is made to make sure details on our Platform are accurate, we may from time to time discover an error in the
      pricing of products. If we discover an error in the pricing of a product in your order, we will let you know as soon as possible. We
      shall be under no obligation to accept or fulfil an order for a product that was advertised at an incorrect price and reserve the
      right to cancel such an order that has been accepted or is in transit. If you order a product that is priced incorrectly for any
      reason, we will email or telephone you to inform you that we have not accepted your order and/or that the relevant part of your order
      has been cancelled. If you have already paid for the goods, we shall refund the full amount as soon as we are able. In the event that
      products are recalled in transit, we will process your refund once the products have been returned to us.</p>
    <a name="payment" />
    <h2>PAYMENT</h2>
    <p>Payment can be made by Visa, MasterCard, and any other methods which may be clearly advertised on the Platform from time to time. You
      confirm that the credit/debit card that is being used is yours or that you have been specifically authorized by the owner of the
      credit/debit card to use it. All credit/debit card holders are subject to validation checks and authorization by the card issuer.</p>
    <p>If the issuer of your credit/debit card refuses to authorize payment to Leem, we will not be liable for any delay or non-delivery.
      Occasionally, high demand causes us to run out of certain items. If we don't have an item that you have ordered in stock, we'll notify
      you by email immediately.</p>
    <p>Sometimes, an item is out of stock and not expected to come back in stock, so we must cancel the item. In this case, if you paid with
      a credit/debit card, we will refund you the value of the item or order including any taxes, shipping or handling charges.</p>
    <p>We take reasonable care to make our Platform is secure. All credit/debit card transactions on this Platform are processed using
      Hyperpay, a secure online payment gateway that encrypts your card details in a secure host environment</p>
    <p>To help ensure that your shopping experience is safe, simple and secure Leem uses Secure Socket Layer (SSL) technology.</p>
    <p>Furthermore, we take reasonable care, in so far as it is in our power to do so, to keep the details of your order and payment secure,
      but in the absence of negligence on our part we cannot be held liable for any loss you may suffer if a third party procures
      unauthorized access to any data you provide when accessing or ordering from the Platform.</p>
    <a name="promotion" />
    <h2>PROMOTION CODES</h2>
    <p>Promotion codes are non-transferable and there is no cash alternative. Furthermore, they cannot be used in conjunction with any other
      promotion code or offers, and must be redeemed by the date published, if provided.</p>
    <a name="insurance" />
    <h2>INSURANCE AND DELIVERY</h2>
    <p>You will own the goods on and from the later of the point in time when: (a) we receive payment in full, or (b) we deliver the goods
      to you.</p>
    <p>Leem insures each purchase during the time it is in transit until it is delivered to your specified delivery address. We require a
      signature for any goods delivered, at which point responsibility for your purchased goods passes to you. If you have specified a
      recipient who is not you for delivery purposes (for example as a gift) then you accept that evidence of a signature by them (or at
      that delivery address) is evidence of delivery and fulfillment by Leem, and transfer of responsibility in the same way. The goods are
      your responsibility from the time we deliver them to the address you gave us.</p>
    <p>Please note that we aim to dispatch all orders within 1-2 business days. Delivery times appearing on the Platform are estimates and
      are to be used as a guide only and commence from the date of dispatch. In any event, we will deliver the goods to you within 30 days
      of the day on which we accept your order. Leem is not responsible for any delays caused by destination customs clearance
      processes.</p>
    <p>If delivery is delayed by any event outside of our control, we will contact you as soon as possible to let you know and we will take
      steps to minimize the effect of the delay. Provided we do this, we will not be liable for delays caused by the event, but if there is
      a risk of substantial delay you may contact us to cancel your order and we will give you a refund for any goods you have paid for but
      not received.</p>
    <a name="returns" />
    <h2>RETURNS AND EXCHANGES</h2>
    <h4>ARRANGING YOUR EXCHANGE OR RETURN</h4>
    <p>You must request your exchange or return and send your unwanted items back to us within 15 days for a refund of the value of the item
      only, or an exchange on the same item in a different size, based on stock availability. Please refer to our Exchanges & Returns policy
      for further details on how to request your return.</p>
    <h4>RETURNING YOUR PURCHASE</h4>
    <p>Making a return for a refund, store credit, or exchange is easy using either our Domestic Returns or International Returns service.
      All items must be sent back to us within 15 days of receiving your order. For full details on how to return your purchase see our
      Exchanges & Returns policy.</p>
    <h4>EXCHANGES</h4>
    <p>Please note you can only exchange items for a different size, based on stock availability. If you wish to exchange your item for an
      alternative product, we suggest that you return it and purchase the new item separately.</p>
    <p>Shipping is complementary on the replacement item, but exchanges made to destinations outside of the Saudi Arabia and the UAE. will
      be processed for customs clearance and therefore any relevant import duty and tax charges will apply. See costs for Shipping and
      Returns.</p>
    <h4>OUR POLICY</h4>
    <p>Items should be returned unused and with all Leem garment tags still attached. Returns that are damaged or soiled will not be
      accepted and will be returned to the customer.</p>
    <p>Where provided, belts and any packaging such as dust bags and leather tags should be included with your return.</p>
    <p>Please email customercare@leem.com if any of your purchases have been delivered without Leem tags.</p>
    <h4>DEFECTIVE GOODS</h4>
    <p>Goods are classified as defective if they are not of satisfactory quality, fit for purpose or as described. Please note that items
      which are damaged or as a result of normal wear and tear; by accident; or through misuse will not be considered defective.</p>
    <p>If your item is defective when you receive it, you can return it for a refund within 15 days from the date you received it. If you
      have owned your item for longer than this, and certainly over 6 months, then please contact Customer Service team.</p>
    <h4>COLORS</h4>
    <p>We have made every effort to display as accurately as possible the colors of our products that appear on the Platform. We cannot
      guarantee that your computer monitor's display of any color will be accurate.</p>
    <h4>FINAL SALE</h4>
    <p>During our sale clearance, products marked as Sale cannot be returned or exchanged.</p>
    <a name="intellectual" />
    <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
    <p>You acknowledge and agree that all copyright, designs, the "look and feel" of the Platform, trademarks and all other intellectual
      property and material rights relating to the Content as herein described, including Leem Software and all HTML and other code
      contained in this Platform, shall remain at all times vested in Leem and/or are the property of their respective owners. All such
      Content, including third party trademarks, designs and related intellectual property rights mentioned or displayed on this Platform
      are protected by laws and regulations and international treaty provisions. You are permitted to use the Content only as expressly
      authorized by Leem and/or its third party licensors. Any reproduction or redistribution of the above listed Content is prohibited and
      may result in civil and criminal penalties. Violators will be prosecuted to the fullest extent permissible under applicable law.
      Without limiting the foregoing, copying and use of the above listed materials to any other server, location or support for
      publication, reproduction or distribution is expressly prohibited.</p>
    <a name="content" />
    <h2>CONTENT</h2>
    <p>In addition to the Intellectual property rights mentioned above, "Content" is defined as all information such as the "look and feel"
      of the Platform, data files, graphics, text, photographs, drawings, logos, images, sounds, music, video or audio files on this
      Platform. Leem tries to ensure that the information on this Platform is accurate and complete. Leem does not warrant or represent that
      Leem’s Content is accurate, error-free or reliable or that your use of Leem's Content will not infringe rights of third parties. Your
      use of the Platform is at your risk. Leem does not warrant that the functional aspects of the Platform or Leem's Content will be error
      free or that this Platform, Leem Content or the server that makes it available are free of viruses or other harmful components. If
      your use of this Platform, or Leem's Content results in the need for servicing or replacing property, material, equipment or data,
      Leem is not responsible for those costs. Without limiting the foregoing, everything on the Platform is provided to you "as is" and "as
      available" without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of
      merchantability, satisfactory quality, fitness for a particular purpose, reasonable care and skill, or non-infringement. Leem and its
      suppliers make no warranties about Leem Content, software text, downloads, graphics, and links, or about results to be obtained from
      using the Platform. You bear the entire risk of the completeness, accuracy or usefulness of any Content found on this Platform. Leem
      reserves the right to withdraw, temporarily or permanently, any Content from this Platform at any time and for any reason. Removal may
      be immediate and without notice. You confirm that Leem is not liable to you or any third party for any such withdrawal.</p>
    <p>We may provide the functionality to allow customers to provide Product ratings and reviews (“User Content”) in relation to the
      Products. If so, customers are solely responsible for such User Content, and you warrant that any User Content you post is accurate
      and that you are the sole author. We do not necessarily review User Content prior to it being posting and it may not reflect our views
      or opinions. You hereby assign to us all intellectual property rights in any User Content you post on the Platform. We make no
      warranty as to the accuracy or reliability of such User Content. We reserve the right to remove User Content which in our sole opinion
      is deemed to be incorrect, offensive, illegal or otherwise unacceptable. User Content which provides negative reviews of Products will
      generally be deemed acceptable when objective, not otherwise in breach of these Terms & Conditions and posted by a verified
      customer.</p>
    <a name="use" />
    <h2>NO COMMERCIAL USE</h2>
    <p>This Platform is for your personal non-commercial use only. You may not modify, copy, distribute, transmit, display, perform,
      reproduce, publish, license, commercially exploit, create derivative works from, transfer, or sell any Content, software, products, or
      services contained within this Platform. You may not use this Platform, or any of its Content, to further any commercial purpose,
      including any advertising or advertising revenue generation activity on your own Platform.</p>
    <a name="activity" />
    <h2>YOUR ACTIVITY</h2>
    <p>You use this Platform at your sole risk. You agree that you will be personally responsible for your use of this Platform and for all
      of your communication and activity on this Platform. We reserve the right to deny you access to this Platform, or any part of this
      Platform, at any time without notice. If we determine, in our sole discretion, that you engaged in prohibited activities, were not
      respectful of other users, or otherwise violated the T&C, we may deny you access to this Platform on a temporary or permanent basis
      and any decision to do so is final.</p>
    <p>You agree that you shall not remove, obscure, or alter any proprietary rights notices (including copyright and trade mark notices)
      which may be affixed to or contained within the Platform.</p>
    <p>You agree not to collect or harvest any personally identifiable information, including account names, from the Platform, nor to use
      the communication systems provided by the Platform for any commercial solicitation purposes. You agree not to solicit, for commercial
      purposes, any users of the Platform with respect to any submissions made by them.</p>
    <a name="third" />
    <h2>THIRD PARTY</h2>
    <p>We may include hyperlinks on this Platform to other Platforms or resources operated by parties other than Leem, including
      advertisers. Leem has not reviewed all of the Platforms linked to its Web Platform and is not responsible for the content or accuracy
      of any off-site pages nor are we responsible for the availability of such external Platforms or resources, and do not endorse and are
      not responsible or liable, directly or indirectly, for the privacy practices or the content of such sites , including (without
      limitation) any advertising, products or other materials or services on or available from such Platforms or resources, nor for any
      damage, loss or offence caused or alleged to be caused by, or in connection with, the use of or reliance on any such content, goods or
      services available on such external Platforms or resources.</p>
    <a name="general" />
    <h2>GENERAL</h2>
    <p>While Leem will use reasonable endeavors to verify the accuracy of any information it places on the Platform, it makes no warranties,
      whether express or implied in relation to its accuracy. This Platform is provided on an "as is" and "as available" basis without any
      representation or endorsement made and we make no warranties of any kind, whether express or implied, in relation to this Platform, or
      any transaction that may be conducted on or through this Platform including but not limited to, implied warranties of
      non-infringement, compatibility, security, accuracy, conditions of completeness, or any implied warranty arising from course of
      dealing or usage or trade.</p>
    <p>Although our Website follows high levels of Information Security Standards We make no warranty that this Platform will meet your
      requirements or will be uninterrupted, timely, secure or error-free, that defects will be corrected, or that this Platform or the
      server that makes it available are free of viruses or bugs or represents the full functionality, accuracy, reliability of the
      materials. We will not be responsible or liable to you for any loss of Content or material uploaded or transmitted through this
      Platform.</p>
    <p>To the fullest extent permissible under applicable law, we disclaim any and all warranties of any kind, whether express or implied,
      in relation to the products available through this Platform including but not limited to, implied warranties of satisfactory quality
      and fitness for a particular purpose. Nothing in these T&C shall limit your rights as a consumer under the laws of Saudi Arabia.</p>
    <p>You acknowledge that we cannot guarantee and therefore shall not be in any way responsible for the security or privacy of this
      Platform and any information provided to or taken from this Platform by you.</p>
    <p>You agree that, except for death and personal injury arising from our negligence, We will not be liable in contract, tort (including,
      without limitation, negligence), pre-contract or other representations (other than fraudulent misrepresentations) or otherwise out of
      or in connection with this contract, for any economic losses (including without limitation, loss of revenues, profits, contracts,
      business or anticipated savings), any loss of goodwill or reputation, or any special or indirect or consequential losses in any case
      whether or not such losses were incurred by that party arising out of or in connection with the provisions of any matter under the
      T&C.</p>
    <a name="indemnification" />
    <h2>INDEMNIFICATION</h2>
    <p>At our request, you agree fully to defend, indemnify and hold harmless Leem immediately on demand, its officers, directors, agents,
      affiliates, licensors, and suppliers, from and against all liabilities, claims, expenses, damages and losses, including legal fees,
      arising from any breach of the T&C by you or any other liabilities arising out of your use of this Platform, or the use by any other
      persons accessing this Platform using your Internet account. We reserve the right, at our own expense, to assume the exclusive defense
      and control of any matter otherwise subject to indemnification by you hereunder. This defense and indemnification obligation will
      survive these T&C and your use of the Platform and the Services.</p>
    <a name="legal" />
    <h2>GENERAL LEGAL TERMS</h2>
    <ul>
      <li>(a) Complete Terms: The T&C constitute the whole legal agreement between you and Leem and govern your use of the Services and
        completely replace any prior agreements between you and Leem in relation to the Services. Notwithstanding the foregoing, you
        understand that Leem may make changes to the T&C from time to time. When these changes are made, Leem will make a new copy of the
        T&C available at http://www.leem.com. You agree that Leem is under no obligation to provide you with notices regarding changes to
        the T&C. You understand that it is your responsibility to check the T&C regularly for changes.
      </li>
      <li>(b) Modifications to the Services: Leem is constantly innovating in order to provide the best possible experience for its users.
        You acknowledge and agree that the form and nature of the Services which Leem provides may change from time to time without prior
        notice to you. You further acknowledge and agree that Leem may stop (permanently or temporarily) providing the Services (or any
        features within the Services) to you or to users generally at Leem's sole discretion, without prior notice to you.
      </li>
      <li>(c) Confidentiality: You understand that Leem grants the operators of public search engines permission to use spiders to copy
        materials from the Platform for the sole purpose of creating publicly available searchable indices of the materials, but not caches
        or archives of such materials. Leem reserves the right to revoke these exceptions either generally or in specific cases.
      </li>
      <li>(d) Liability in the Event of Breach: You agree that you will comply with all of the provisions of the T&C. You understand that
        you are solely responsible for (and that Leem has no responsibility to you or to any third party for) any breach of your obligations
        under the T&C and for the consequences including any loss or damage which Leem may suffer) of any such breach.
      </li>
      <li>(e) Rights Not Waived: You agree that if Leem does not exercise or enforce any legal right or remedy which is contained in the
        T&C (or which Leem has the benefit of under any applicable law), this will not be taken to be a formal waiver of Leem's rights and
        that those rights or remedies will still be available to Leem.
      </li>
      <li>(f) Severability: If any court of law, having the jurisdiction to decide on this matter, rules that any provision of these T&C
        is invalid, then that provision will be removed from the T&C without affecting the rest of the T&C. The remaining provisions of the
        T&C will continue to be valid and enforceable.
      </li>
      <li>(g) Governing Law: The T&C, and your relationship with Leem under the T&C, shall be governed by the laws of Kingdom of Saudi
        Arabia. You and Leem agree to submit to the exclusive jurisdiction of Saudi Arabian courts in Riyadh, and waive any claim or defense
        of inconvenient forum or lack of personal jurisdiction in such forum under any applicable law or decision or otherwise.
      </li>
      <li>(h) Violation of T&C: Please report any violations of the T&C by emailing customercare@leem.com.</li>
      <li>(I) Independent Relationship: You and Leem are independent contractors, and these T&C, including but not limited to submission
        or distribution of any Content you created, will not, in whole or in part, establish any relationship of partnership, joint venture,
        employment, franchise or agency between the you and Leem. Neither party will have the power to bind the other or incur obligations
        on the other's behalf without the other's prior written consent. Neither party is authorized to act as an agent nor representative
        of the other or for or on behalf of the other party in any capacity other than as expressly set forth in the T&C. Neither party
        shall in any manner advertise, represent or hold itself (or any of its agents) out as so acting or being authorized so to act, or
        incur any liabilities or obligations on behalf of, or in the name of, the other party, unless specifically provided for in the T&C.
      </li>
    </ul>
  </Layout>
);

export default IndexPage
